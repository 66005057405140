body {
  font-size   : 14px !important;
  color       : #575962 !important;
  font-family : Lato, sans-serif !important;
}

.table, body, tr:hover {
  color: #575962 !important;
}

tr:hover {
  color            : #575962 !important;
  background-color : rgba(0, 0, 0, .075);
}

select,
input[type=search] {
  display          : block;
  width            : 100%;
  line-height      : 1.5;
  color            : #495057;
  background-color : #fff;
  background-clip  : padding-box;
  border           : 1px solid #ced4da;
  border-radius    : .25rem;
  transition       : border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  padding     : .25rem .5rem !important;
  font-size   : .875rem !important;
  line-height : 1.5;

}

.dataTables_length {
  width : 200px;
  float : left;
}

.paginate-button {
  /* background    : #00665d; */
  width         : 25px;
  height        : 25px;
  border-radius : 10%;
  /* border        : 1px solid #019689; */
  border: none;
  /* color         : #fff; */
  text-align    : center;
  line-height   : 12px;
  cursor        : pointer;
  margin: 0 2px;
  text-decoration: none;
  /* font-weight: bold; */
}

.paginate-button:hover {
  color: #00665d;
}

.active-btn {
  width         : 30px;
  height        : 30px;
  font-weight: bolder;
  font-size: 14px;
  color         : #fff;
  background    : #00665d;
}

.p-next {
  /* color          : #777; */
  /* border         : 1px solid #dee2e6; */
  padding        : 5px;
  border-radius  : 6px;
  font-size      : 12px;
  text-transform : capitalize;
  margin-left: 8px;
  cursor         : pointer;
  /* background: none; */
  border: none;
}

.p-previous {
  /* color          : #777; */
  /* border         : 1px solid #dee2e6; */
  padding        : 5px;
  border-radius  : 6px;
  font-size      : 12px;
  text-transform : capitalize;
  margin-right: 8px;
  cursor         : pointer;
  /* background: none; */
  border: none;
}

.paginate_button:hover {
  text-decoration : none;
}

div.dataTables_wrapper div.dataTables_info {
  float       : left;
  padding-top : 0px !important;
  font-size   : 14px;
  color       : #575962;
  height      : 40px;
}

.table-responsive {
  overflow-y : hidden;
}

.table-responsive [data-action] {
  cursor : pointer;
  color  : #00665d !important;
}

span a.paginate_button.current {
  background    : #00665d;
  width         : 35px;
  height        : 35px;
  border-radius : 50%;
  display       : inline-block;
  color         : #fff;
  text-align    : center;
  line-height   : 32px;
  cursor        : pointer;
  text-decoration: none;
}

span a.paginate_button.current:hover {
  background : #019689;
  color      : #fff;
}

span a.paginate_button {
  background    : #fff;
  width         : 35px;
  height        : 35px;
  border-radius : 50%;
  display       : inline-block;
  color         : #333;
  text-align    : center;
  line-height   : 32px;
  border        : 1px solid #019689;
  margin        : 0 2px;
  cursor        : pointer;
}

span a.paginate_button:hover {
  color            : #0056b3;
  text-decoration  : none;
  background-color : #019689;
  border-color     : #019689;
}

.th-custom {
	position: relative;
   vertical-align: top !important;
}

.th-custom button {
  width: 100%;
  height: 100%;
}