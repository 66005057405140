:root {
  --loader-container-bg: rgba(0, 0, 0, 0.6);
  --loader-container-fg: #DADADA;
}

.loader-container {
  /* @apply fixed flex inset-0 w-full h-full justify-center align-items-center; */
  z-index: 1000;
  /* background: var(--loader-container-bg); */
  background-color: rgba(0, 0, 0, 0.432);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* > div {
    @apply animate-spin;
    color: var(--loader-container-fg);
    font-size: 4rem;
  } */
}
