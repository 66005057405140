.login .login-aside {
	padding: 25px;
  height: 100vh;
  overflow-y: auto;
}
.login-form {
	padding-bottom: 50px;
}
.login .custom-control-label {
	display: block;
}
.dataTables_wrapper div.dataTables_info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 80%;
}



@media screen and (max-width: 991px){
	.logo-header .navbar-toggler {
		position: absolute;
	  left: 10px;
	}
	.main-header .logo-header h5 {
		position: absolute;
    left: 50%;
    transform: translateX(-50%);
	}
	.main-header .logo-header h5 i.fa-tree {
		display: inline-block;
	}

 .main-header .logo-header i.fa-tree {
 	display: none;
 }

	.login .custom-control-label {
    font-weight: 400;
    white-space: nowrap;
    box-sizing: content-box;
    display: block;
    white-space: normal;
	}
	
}

@media screen and (max-width: 767px){
.dataTables_paginate.paging_simple_numbers {
  clear: both;
  display: flex;
  align-items: center;
  width: 80%;
}
	
div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_paginate {
	text-align: left;
}

.nav.nav-line .nav-link {
	padding: 10px 15px;
}
.container-login {
    width: 100%!important;
    padding: 0px !important;
	}
	.container-login img {
    margin-bottom: 0px;
    width: 200px;
    margin-left: auto !important;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
	}
	.login .login-aside .subtitle {
    font-size: 16px;
    margin-bottom: 0px;
	}
	.login .login-aside .title {
    font-size: 24px;
    margin-bottom: 0 !important;
	}
	.form-check, .form-group {
		padding: 0px;
	}
	.login .login-aside {
    padding: 15px;
    height: auto;
  }

	.container-signup img {
    margin-bottom: 0px;
    width: 200px;
    margin-left: auto !important;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
	}
	.login .wrapper.wrapper-login .container-login, 
	.login .wrapper.wrapper-login .container-signup {
    width: 100%;
    padding: inherit;
    border-radius: 5px;
	}

	
}

