.dashboard-section .h1{
	font-size: 1.725rem;
}

.dashboard-section h1{
	 font-size: 1.725rem;
}

.dashboard-section h2{
	font-size: 1.35rem;
}

.dashboard-section h3{
	font-size: 1.1625rem
}

.dashboard-section h4{
	font-size: 1.0375rem;
}

.dashboard-section h5{
	font-size: .9125rem;
}

.dashboard-section h6{
	font-size: .825rem;
}

.dashboard-section .text-info, .dashboard-section .text-info a {
    color: #48abf7!important;
}

.btn-icon.btn-xs{
	padding: inherit !important;
}

.card-title{
	margin-bottom: 0 !important;
}

.btn.btn-sm{
	padding: 7px 13px !important;
	font-size: 11px !important;
}

.main-header .nav-search input::placeholder{
	color: #fff;
}

.card{
	box-shadow: none !important;
}

.table-sales table tr:first-child td{
	border: none;
}

.btn-icon.btn-xs i{
	position: relative;
  top: -7px;
}